const selectContentImage = (
  destinationContent,
  sourceContent = destinationContent
) => {
  const contentWithSelectedImage = {
    ...destinationContent,
    cover_image:
      sourceContent?.cover_image?.cropped_image ||
      sourceContent?.cover_image?.source_image ||
      sourceContent?.cover_image_old ||
      null,

    thumbnail_image:
      sourceContent?.thumbnail_image?.cropped_image ||
      sourceContent?.thumbnail_image?.source_image ||
      sourceContent?.thumbnail_image_old ||
      null,
  };

  delete contentWithSelectedImage.cover_image_old;
  delete contentWithSelectedImage.thumbnail_image_old;

  return contentWithSelectedImage;
};

export default selectContentImage;
