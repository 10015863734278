/* 
 "articles" argument example:
 [
  {
    ...
    published_at: "2021-11-01T16:49:07.614Z"
    published_date: "2022-02-04T17:00:00.000Z"
    ...
  },
  {
    ...
    published_at: "2021-11-01T16:49:07.614Z"
    published_date: "2022-02-04T17:00:00.000Z"
    ...
  }
 ]
*/

const sortByPublishedDate = (articles) => {
  if (!Array.isArray(articles)) return [];
  if (articles.length <= 0) return [];

  return articles.sort((a, b) => {
    const publishedDateA = a.published_date || a.published_at;
    const publishedDateB = b.published_date || b.published_at;

    if (publishedDateA > publishedDateB) {
      return -1;
    }
    if (publishedDateA < publishedDateB) {
      return 1;
    }
    return 0;
  });
};

export default sortByPublishedDate;
