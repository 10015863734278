import React from 'react';
import getSectionBackgroundColor from './helpers/methods/getSectionBackgroundColor';
import classNames from 'classnames';

const Section = ({
  children,
  className,
  color,
  isVisible = true,
  id = null,
}) => {
  const sectionBG = getSectionBackgroundColor(color);

  const sectionContainerClassName = classNames({
    [`section-container ${sectionBG} ${className}`]: isVisible,
    _undisplayed: !isVisible,
  });

  return (
    <section id={id} className={sectionContainerClassName}>
      <div className="section-item-container">{children}</div>
    </section>
  );
};

export default Section;
