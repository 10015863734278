import React from 'react';
import CardCarousel from '@components/CardCarousel';

const RelatedCourses = ({ relatedCourses = [] }) => {
  const carouselStyle = relatedCourses?.length === 1 && 'single-card-wrapper';

  return (
    <div className="related-courses-container">
      <CardCarousel
        title="Related courses"
        items={relatedCourses}
        className={carouselStyle}
        linkRoot="/career-development/courses/"
        type="courseCard"
      />
    </div>
  );
};

export default RelatedCourses;
