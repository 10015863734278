import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Header from '@components/Layout/Headers/JobseekersHeader';
import Main from '@components/Layout/Main';
import Footer from '@components/Layout/Footer';
import Subheader from './Subheader';
import SubscribeEmail from '@components/SubscribeEmail';
import StudentTestimonials from '@components/StudentTestimonials';
import RelatedCourses from '@components/RelatedCourses';
import EmptyState from '@components/EmptyState';
import Section from '@components/Section';
import LiveChatWidgetScript from '@components/LiveChatWidgetScript';
import '@scss/main.scss';

const CareerDevelopmentLayout = ({
  children,
  path,
  className,
  testimonials,
  relatedCourses,
  isReleased = true,
}) => {
  const subscribeEmailProps = {
    postURL: `${process.env.GATSBY_API_URL}/jobseekers-email-subscriptions`,
    ctaHeading: 'Stay Connected!',
    ctaDescription: "We'll keep you up to date for latest posts and courses.",
    successHeading: 'Thank you!',
    successDescription:
      'Thank you for subscribing to our newsletter. You should receive a confirmation email soon.',
    subscriptionInfo: '',
  };

  return (
    <>
      <div>
        <Header />
        <Subheader />
        {!isReleased ? (
          <EmptyState />
        ) : (
          <div className={'career-development-layout'}>
            <Main className={className}>{children}</Main>
            {isEmpty(path) && <SubscribeEmail {...subscribeEmailProps} />}
            {!isEmpty(testimonials) && (
              <StudentTestimonials items={testimonials} />
            )}
            {!isEmpty(relatedCourses) && (
              <Section>
                <RelatedCourses relatedCourses={relatedCourses} />
              </Section>
            )}
          </div>
        )}

        <Footer />
      </div>
      <LiveChatWidgetScript />
    </>
  );
};

export default CareerDevelopmentLayout;
