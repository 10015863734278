const getSectionBackgroundColor = (color) => {
  switch (color) {
    case 'white':
      return '_bg-white';
    case 'ash':
      return '_bg-ash';
    default:
      return '_bg-white';
  }
};

export default getSectionBackgroundColor;
